* {
  -webkit-font-smoothing: antialiased;
  line-height: 1.6em;
  box-sizing: border-box;
}

a:focus, button:focus {
  outline: 2px solid #4bf1f1;
}

p {
  line-height: 1.35em;
}

body {
  background-color: #f5f5f5;
  font-family: "ff-meta-serif-web-pro", Georgia;
}
